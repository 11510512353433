import React from "react";
import { Routes, Route } from "react-router-dom";
import Domains from "../Pages/Domains";
import Dashboard from "../Pages/Dashboard";
import Keywords from "../Pages/Keywords";
import Subscription from "../Pages/Subscription";
import Profile from "../Pages/Profile";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/domains" element={<Domains />} />
      <Route path="/domains/:domainId" element={<Keywords />} />
      <Route path="/payment" element={<Subscription />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/*" element={<Dashboard />} />
    </Routes>
  );
};

export default AppRoutes;
