import { DesktopOutlined, PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "../Assets/Styles/pages.css";
import { Button, Table, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { getDashboard } from "../API/getDashboard";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [size] = useState("large");
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getDashboard();
        if (Math.floor(res.status / 100) === 2) {
          setDashboardData(res.data);
        } else {
          console.error("Failed to fetch dashboard data:", res.data);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching dashboard data:",
          error
        );
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleAddDomain = () => {
    navigate("/domains");
  };

  const handleNavigateKeyword = (id) => {
    navigate(`/domains/${id}`);
  };

  return (
    <div className="tab-view content">
      <Tabs defaultActiveKey="1" type="card" size={size}>
        <TabPane tab="Dashboard" key="1" icon={<DesktopOutlined />}>
          <div className="content-dashboard">
            <div className="add-domain-wrapper">
              <Button
                style={{ backgroundColor: "#FBB200", color: "white" }}
                icon={<PlusCircleOutlined />}
                onClick={handleAddDomain}
              >
                Domains
              </Button>
            </div>
            <Table
              loading={loading}
              dataSource={dashboardData}
              pagination={false}
              scroll={{ x: "768" }}
            >
              <Table.Column
                title="Domain Name"
                dataIndex="domainName"
                key="domainName"
                render={(value, record) => {
                  return (
                    <div className="domain-styling">
                      <span className="domain-name">{value}</span>
                      <span className="domain-url">{record.url}</span>
                    </div>
                  );
                }}
              />
              <Table.Column
                title="Number of Keywords"
                dataIndex="numKeywords"
                key="numKeywords"
                render={(value, record) => {
                  if (value === 0) {
                    return (
                      <>
                        <Button
                          onClick={() => handleNavigateKeyword(record?.id)}
                          style={{ backgroundColor: "#FBB200", color: "white" }}
                        >
                          <PlusCircleOutlined />
                        </Button>
                      </>
                    );
                  } else return value;
                }}
              />
              <Table.Column
                title="Discovered Keywords"
                dataIndex="discoveredKeywords"
                key="discoveredKeywords"
                render={(value, record) => {
                  return (
                    <div className="discovered-keywords">
                      <span className="discovered-keywords-value">{value}</span>
                      <div>
                        <img
                          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${record.location}.svg`}
                          alt={record.location}
                          width={30}
                        />
                        {/* {" , "}
                        <span>{record?.city}</span> */}
                      </div>
                    </div>
                  );
                }}
              />
              <Table.Column
                title="Average Rank"
                dataIndex="averageRank"
                key="averageRank"
              />
            </Table>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Dashboard;
