import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Button, Avatar } from "antd";
import { LogoutOutlined, CaretDownOutlined } from "@ant-design/icons";
import defaultPicture from "../../Assets/Images/default picture.png";
import "../../Assets/Styles/layout.css";
import { useNavigate, useParams } from "react-router-dom";
import { handleLogout } from "../../Utils/UpdateUsersState";
import { getDomain } from "../../API/domainApis";

const { Header } = Layout;

const Navbar = ({ setUserData, setUserToken }) => {
  const navigate = useNavigate();
  const [domainName, setDomainName] = useState("");
  const handleLogoutPage = () => {
    navigate("/login");
    handleLogout(setUserData, setUserToken);
  };
  useEffect(() => {
    const url = window.location.href;

    const match = url.match(/\/domains\/([^/]+)/);

    if (match && match[1]) {
      setDomainName(localStorage.getItem("domain") || "");
    } else {
      setDomainName("");
    }
  }, [window.location.href]);
  const menu = (
    <Menu>
      <Menu.Item
        key="logout"
        onClick={handleLogoutPage}
        icon={<LogoutOutlined />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Header
      className="navbar"
      style={{
        background: "#001628",
        padding: 0,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          fontSize: "18px",
          color: "white",
          fontWeight: "600",
          marginLeft: "1rem",
        }}
      >
        {domainName ? domainName : ""}
      </div>
      <div style={{ float: "right", marginRight: "16px" }}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="text" style={{ padding: 0 }}>
            <Avatar src={defaultPicture} alt="Profile" size={40} />
            <CaretDownOutlined
              style={{ marginLeft: "8px", color: "#D3D3D3", fontSize: "10px" }}
            />
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

export default Navbar;
