import React, { useEffect, useState } from "react";
import AppLayout from "./Components/Layout/AppLayout";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import PublicRoutes from "./Routes/PublicRoutes";
import updateUserStates, { handleLogout } from "./Utils/UpdateUsersState";
import { userDetails } from "./API/userDetails";
import axios from "axios";
import { decryptText, encryptText } from "./Utils/Encryption";
import { refresh } from "./API/auth";

const App = () => {
  const [userData, setUserData] = useState({
    username: "",
    role: "",
    id: 0,
    account: false,
  });
  const [userToken, setUserToken] = useState({
    access: "",
    refresh: "",
  });
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [search, setSearch] = useState("");
  const [notificationType, setNotificationType] = useState(null);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        const localStorageItems = localStorage;

        if (localStorageItems.length !== 0) {
          for (let key in localStorageItems) {
            if (localStorageItems[key] && decryptText(key) === "user") {
              localStorage.removeItem(key);
            }

            if (localStorageItems[key] && decryptText(key) === "token") {
              localStorage.removeItem(key);
            }
          }
        }
        window.location.href = "/login";
      }
    }
  );

  useEffect(() => {
    const func = async () => {
      if (userData.role === "" || userToken.access === "")
        updateUserStates(setUserData, setUserToken);
      setLoading(false);
    };
    func();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (
        userToken.access &&
        userToken.refresh &&
        userData.username &&
        !loading
      ) {
        const data = await refresh(userToken);

        if (data?.status === 200) {
          const localStorageItems = localStorage;
          if (localStorageItems.length !== 0) {
            for (let key in localStorageItems) {
              if (localStorageItems[key] && decryptText(key) === "token") {
                localStorage.removeItem(key);
              }
            }
          }
          localStorage.setItem(
            encryptText("token"),
            encryptText(
              JSON.stringify({
                access: data?.data?.access,
                refresh: data?.data?.refresh,
              })
            )
          );
          setUserToken({
            access: data?.data?.access,
            refresh: data?.data?.refresh,
          });
        } else {
          handleLogout(setUserData, setUserToken);
          setUser({});
        }
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [loading, userToken, userData, window.location.pathname]);

  useEffect(() => {
    const func = async () => {
      if (userData?.id) {
        const res = await userDetails(userToken);
        setUser(res?.data);
      }
    };
    func();
  }, [userData]);

  return (
    <div className="App">
      {loading ? null : (
        <BrowserRouter>
          {userData.username ? (
            <AppLayout
              setUserData={setUserData}
              setUserToken={setUserToken}
              userData={userData}
            />
          ) : (
            <PublicRoutes
              setUserData={setUserData}
              userToken={userToken}
              setUserToken={setUserToken}
              userData={userData}
              user={user}
              setUser={setUser}
              notificationType={notificationType}
              setNotificationType={setNotificationType}
            />
          )}
        </BrowserRouter>
      )}
    </div>
  );
};

export default App;
