import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const userDetails = async (token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/user/`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const usersList = async (token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/list`,
      {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const deleteUser = async (token, id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const updateUser = async (user) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/`,
      user,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    return false;
    console.log(e);
  }
};

export { userDetails, usersList, updateUser, deleteUser };
