import { decryptText, encryptText } from "./Encryption";

const updateUserStates = (setUserData, setUserToken) => {
  const localStorageItems = localStorage;

  if (localStorageItems.length !== 0) {
    for (let key in localStorageItems) {
      if (localStorageItems[key] && decryptText(key) === "user") {
        localStorageItems[key] &&
          setUserData(JSON.parse(decryptText(localStorageItems[key])));
      }

      if (localStorageItems[key] && decryptText(key) === "token") {
        localStorageItems[key] &&
          setUserToken(JSON.parse(decryptText(localStorageItems[key])));
      }
    }
  }
};

const getToken = () => {
  const localStorageItems = localStorage;

  if (localStorageItems.length !== 0) {
    for (let key in localStorageItems) {
      if (localStorageItems[key] && decryptText(key) === "token") {
        return JSON.parse(decryptText(localStorageItems[key])).access;
      }
    }
  }
};

const getUser = async () => {
  const localStorageItems = localStorage;

  if (localStorageItems.length !== 0) {
    for (let key in localStorageItems) {
      if (localStorageItems[key] && decryptText(key) === "user") {
        return JSON.parse(decryptText(localStorageItems[key]));
      }
    }
  }
};

const handleLogout = (setUserData, setUserToken) => {
  const localStorageItems = localStorage;

  if (localStorageItems.length !== 0) {
    for (let key in localStorageItems) {
      if (localStorageItems[key] && decryptText(key) === "user") {
        localStorage.removeItem(key);
      }

      if (localStorageItems[key] && decryptText(key) === "token") {
        localStorage.removeItem(key);
      }
    }
    setUserData({ username: "", role: "", account: false });
    setUserToken({ access: "", refresh: "" });
  }
};

const handleUpdate = (userData) => {
  const localStorageItems = localStorage;
  if (localStorageItems.length !== 0) {
    for (let key in localStorageItems) {
      if (localStorageItems[key] && decryptText(key) === "user") {
        localStorage.removeItem(key);
      }
    }
    localStorage.setItem(
      encryptText("user"),
      encryptText(JSON.stringify(userData))
    );
  }
};

export { handleLogout, handleUpdate, getToken, getUser };
export default updateUserStates;
