import React from "react";
import "../Assets/Styles/pages.css";
import { Button, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { DollarCircleOutlined, CreditCardOutlined } from "@ant-design/icons";
import visa from "../Assets/Images/visa.png";
import mastercard from "../Assets/Images/mastercard.png";

const Subscription = () => {
  return (
    <div className="tab-view content">
      <Tabs defaultActiveKey="1" type="card" size="large">
        <TabPane tab="Subscription" key="1" icon={<DollarCircleOutlined />}>
          <div className="content-dashboard">Subscription</div>
        </TabPane>
        <TabPane tab="Methods" key="2" icon={<CreditCardOutlined />}>
          <div className="content-dashboard">
            <div className="list-methods">
              <div className="method">
                <div className="method-details">
                  <img className="method-logo" src={visa} alt="visa" />
                  <div className="method-info">
                    <span className="card-number">....9090</span>
                    <span className="expiry-date">Expires 02/2028</span>
                  </div>
                </div>
                <div className="method-buttons">
                  <span className="default">Default</span>
                  <span className="delete">Delete</span>
                </div>
              </div>

              <div className="method">
                <div className="method-details">
                  <img className="method-logo" src={mastercard} alt="visa" />

                  <div className="method-info">
                    <span className="card-number">....5050</span>
                    <span className="expiry-date">Expires 05/2020</span>
                  </div>
                </div>
                <div className="method-buttons">
                  <span className="default">Default</span>
                  <span className="delete">Delete</span>
                </div>
              </div>

              <Button>Add Payment Method</Button>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Subscription;
