import React from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import AppRoutes from "../../Routes/AppRoutes";
import "../../Assets/Styles/layout.css";

const { Content } = Layout;

const AppLayout = ({ setUserData, userData, setUserToken }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout className="site-layout">
        <Navbar setUserData={setUserData} setUserToken={setUserToken} />
        <Content className="">
          <AppRoutes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
