import React, { useEffect } from "react";
import "../Assets/Styles/login.css";
import { Button, Col, Form, Input, Row } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../Assets/Images/logo.png";
import { signup } from "../API/auth";

const Signup = ({ setNotificationType }) => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const signupData = await signup(values.email, values.password, values.name);
    if (signupData?.status === 200) {
      setNotificationType("success");
      navigate("/login");
    } else {
      setNotificationType("error");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  return (
    <div className="box-container">
      <Row className="nav">
        <Col span={5} md={7} sm={10} xs={20} className="data">
          <img style={{ width: "60px" }} src={logo} alt="logo" />
        </Col>
      </Row>
      <div className="login-box">
        <div>
          <span className="title">Sign Up</span>
        </div>
        <div>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 400 }}
            initialValues={{ remember: false }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="form"
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input type="email" placeholder="Email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "12px 0px",
              }}
            >
              <NavLink to="/login">Login Instead?</NavLink>
            </div>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
