import {
  Button,
  Form,
  Modal,
  Table,
  Tabs,
  Tag,
  Space,
  Spin,
  Select,
  Checkbox,
  DatePicker,
} from "antd";
import {
  SearchOutlined,
  GoogleOutlined,
  PlusCircleOutlined,
  LinkOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useRef, useState } from "react";
import "../Assets/Styles/pages.css";
import { getRankings } from "../API/rankings";
import { useParams } from "react-router-dom";
import {
  createKeyword,
  deleteKeyword,
  getAllKeywords,
} from "../API/keywordApis";
import AceEditor from "react-ace";
import { getDomain } from "../API/domainApis";
import { getCity } from "../API/getLocation";
import moment from "moment";

const Keywords = () => {
  const [editorValue, setEditorValue] = useState("");
  const editorRef = useRef();
  const [cityCheck, setCityCheck] = useState(false);
  const { domainId } = useParams();
  const formRef = React.useRef();
  const [keywordData, setKeywordData] = useState([]);
  const [size] = useState("large");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rankingData, setRankingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [optionsCity, setOptionsCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keywordsArray, setKeywordsArray] = useState([]);
  const [domainData, setDomainData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  // const [domainName, setDomainName] = useState(
  //   localStorage.getItem("domain") || ""
  // );

  const updateDate = (date, dateString) => {
    setSelectedDate(dateString);
    fetchRankingData(domainId, dateString);
  };

  const fetchRankingData = async (id, selectedDate) => {
    try {
      setIsLoading(true);
      const res = await getRankings(id, selectedDate);
      if (res.status === 200) {
        const filteredData = selectedDate
          ? res.data.filter((item) => item.dateScraped === selectedDate)
          : res.data;

        setRankingData(filteredData);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e, "Error");
    }
    setIsLoading(false);
  };

  const fetchDomainData = async (id) => {
    try {
      setLoading(true);
      const res = await getDomain(id);
      if (Math.floor(res.status / 100) === 2) {
        setDomainData(res.data);
        setLoading(false);
        // setDomainName(res.data?.domainName);
        return res.data.location;
      }
    } catch (e) {
      console.log(e, "Error");
    }
    setLoading(false);
  };

  const fetchAllKeywords = async (id) => {
    try {
      setLoading(true);
      const res = await getAllKeywords(id);
      if (Math.floor(res.status / 100) === 2) {
        setKeywordData(res.data);

        setLoading(false);
      }
    } catch (e) {
      console.log(e, "Error");
    }
    setLoading(false);
  };

  const onCoutryChange = async (countryCode) => {
    const cities = await getCity(countryCode);
    if (cities.status === 200) {
      setOptionsCity(cities?.data);
    } else {
      setOptionsCity([]);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setSelectedDate(formattedDate);

    const fetchData = async () => {
      fetchRankingData(domainId, formattedDate);
      fetchAllKeywords(domainId);
      const location = await fetchDomainData(domainId);
      onCoutryChange(location);
    };

    fetchData();
  }, [domainId]);

  const showModal = async () => {
    setIsModalVisible(true);
    const location = await fetchDomainData(domainId);
    onCoutryChange(location);
    setCityCheck(false);
  };

  const handleDeleteKeyword = async (id) => {
    try {
      setLoading(true);
      const res = await deleteKeyword(id);
      if (Math.floor(res.status / 100) === 2) {
        setKeywordData(res.data);
      }
    } catch (e) {
      console.log(e, "Error");
    }
    setLoading(false);
  };

  const handleAddKeyword = async (values) => {
    setDisable(true);
    if (!cityCheck) {
      values.city = domainData.city;
    }
    formRef.current.resetFields();

    try {
      setLoading(true);
      const res = await createKeyword(
        domainId,
        keywordsArray,
        values.city,
        cityCheck
      );
      if (Math.floor(res.status / 100) === 2) {
        setKeywordData(res.data);
        setEditorValue("");
      }
    } catch (e) {
      console.log(e, "Error");
    }

    setLoading(false);
    setDisable(false);
  };

  const handleAddKeywordsChange = (value) => {
    const linesArray = value
      .split("\n")
      .map((line) => line.trim())
      .filter(Boolean);
    setKeywordsArray(linesArray);
    setEditorValue(value);
  };

  return (
    <>
      {/* <div className="topbar">
        <div className="domain-box">
          <div className="domain-d">Domain</div>

          <div className="domain-name">{domainName}</div>
        </div>
      </div> */}
      <div className="tab-view content">
        <Tabs defaultActiveKey="1" type="card" size={size}>
          <TabPane tab="Keywords" key="1" icon={<SearchOutlined />}>
            <div className="content-dashboard">
              <div className="buttons-container">
                <div className="add-domain-wrapper">
                  <Button
                    style={{ backgroundColor: "#FBB200", color: "white" }}
                    icon={<PlusCircleOutlined />}
                    onClick={showModal}
                  >
                    Manage Keywords
                  </Button>
                </div>
                <div className="add-domain-wrapper">
                  <DatePicker defaultValue={dayjs()} onChange={updateDate} />
                  <Button
                    style={{
                      marginLeft: "20px",
                      backgroundColor: "rgb(13, 97, 13)",
                      color: "white",
                    }}
                    icon={<SyncOutlined />}
                    onClick={() => fetchRankingData(domainId, selectedDate)}
                  >
                    Refresh
                  </Button>
                </div>
              </div>

              <Table
                dataSource={rankingData}
                pagination={false}
                scroll={{ x: "768" }}
                loading={isLoading}
              >
                <Table.Column
                  title="Keyword"
                  dataIndex="keyword"
                  key="keyword"
                  sorter={(a, b) => a.keyword?.localeCompare(b.keyword)}
                />
                <Table.Column
                  title="Location"
                  dataIndex="location"
                  key="location"
                  render={(value, record) => {
                    return (
                      <div>
                        <img
                          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`}
                          alt={value}
                          width={30}
                        />
                        {record.flag_city && (
                          <span>
                            {" , "}
                            {record.city}
                          </span>
                        )}
                      </div>
                    );
                  }}
                />
                <Table.Column
                  title="Rank"
                  dataIndex="rank"
                  key="rank"
                  sorter={(a, b) => a.rank - b.rank}
                />
                <Table.Column
                  width={150}
                  title="Title"
                  dataIndex="title"
                  key="title"
                  sorter={(a, b) => a.title?.localeCompare(b.title)}
                />
                <Table.Column
                  title="Price"
                  dataIndex="price"
                  key="price"
                  sorter={(a, b) => a.price - b.price}
                />
                <Table.Column
                  title="Date Scraped"
                  dataIndex="dateScraped"
                  key="id"
                />
                <Table.Column
                  title="URL"
                  key="url"
                  render={(_, record) => (
                    <Space style={{ display: "flex", gap: "20px" }}>
                      <Button
                        type="default"
                        icon={<LinkOutlined />}
                        onClick={() => window.open(record.url, "_blank")}
                      >
                        View URL
                      </Button>
                      <Button
                        type="default"
                        icon={<GoogleOutlined />}
                        onClick={() =>
                          window.open(
                            process.env.REACT_APP_BACKEND_URL +
                              "/" +
                              record.google_search_url,
                            "_blank"
                          )
                        }
                      >
                        SERP Page
                      </Button>
                    </Space>
                  )}
                />
              </Table>
            </div>
          </TabPane>
        </Tabs>

        <Modal
          title={"Add Keywords"}
          visible={isModalVisible}
          footer={null}
          onCancel={() => {
            setIsModalVisible(false);
            fetchRankingData(domainId, selectedDate);
            formRef.current.resetFields();
          }}
        >
          <div className="modal-content">
            <div className="keyword-bubbles">
              {loading ? (
                <Spin />
              ) : (
                <Space size={[0, 8]} wrap>
                  {keywordData.map((object, index) => (
                    <Tag
                      key={index}
                      closable
                      onClose={() => handleDeleteKeyword(object.id)}
                      style={{
                        marginBottom: 8,
                      }}
                      color="blue"
                    >
                      {object.keyword}
                    </Tag>
                  ))}
                </Space>
              )}
            </div>
            <Form ref={formRef} layout="vertical" onFinish={handleAddKeyword}>
              <div
                className="input-word"
                style={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Form.Item label="Add Keyword">
                  <AceEditor
                    mode="text"
                    height="200px"
                    onChange={handleAddKeywordsChange}
                    name="keywords"
                    value={editorValue}
                    editorProps={{ blockScrolling: true }}
                    ref={editorRef}
                  />
                </Form.Item>
                <Checkbox
                  checked={cityCheck}
                  onChange={() => setCityCheck(!cityCheck)}
                  style={{ width: "fit-content" }}
                >
                  Select City
                </Checkbox>
                {cityCheck && (
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[{ required: true, message: "Please select City" }]}
                  >
                    <Select
                      options={optionsCity}
                      showSearch
                      placeholder="Select City"
                      optionFilterProp="value"
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <Button
                    loading={disable}
                    htmlType="submit"
                    style={{
                      backgroundColor: "#FBB200",
                      color: "white",
                    }}
                  >
                    ADD
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Keywords;
